<!-- 合格证台账打印功能-->
<template>
<div style="margin: 5 auto;">

    <div style="margin:0 30px;float:right;">
        <Button type="warning"  @click="previewdata">打印预览</Button>
        <Button type="primary" style="margin-left: 15px;" @click="printdata">直接打印</Button>
    </div>
    <div id="partprintdiv" v-if="!loading" style="margin:0 auto;margin-top:1px;width:1300px;height:920px;clear: both;overflow-y: scroll;">
        <div id="contentdiv" v-if="doc !=null"  v-html="doc.doccontent"></div>
    </div>
    <div v-else style="margin: 0 auto;text-align: center;">
        正在读取数据...
    </div>
    <div>

    </div>
</div>
</template>
 <script>
    import {getPrinters,public_print,public_print_with_dir,public_preview_with_dir} from '@/zsjs/zskjprinter.js'
    import * as sysTool from '@/zsjs/systemTool.js'
    export default {
        name:'PartCheckPrint',
        props:['suppliers','cgdetail'],
        data(){
            return {
                modelcfg:this._self.$root.$store.state.ModelCfg,
                doc:null,
                loading:false,
                vins:[]
            }
        },
        mounted(){
            this.handleQuery()
        },
        methods:{
            handleQuery(){
                let postdata={
                    companyid: this._self.$root.$store.state.LogonInfo.companyid,
                    suppliers: this.suppliers,
                    modelMadeResult:this.cgdetail
                }

                this.loading = true
                this.$axios({
                    method:'post',
                    url:'/api/checkdept/doc/partjy/get',
                    data:{'partcheckdata': JSON.stringify(postdata) },
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        console.log(res)
                        this.doc = res.data.data
                        this.$Message.success({content:res.data.msg, duration:3})
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                    this.loading = false
                }.bind(this));  
            },
            getpreDate(maodate){
                return sysTool.getNextDate(maodate,-1)
            },
            formatDate(date){
                if(date !=null)
                    return  (new Date(date)).Format('yyyy-MM-dd')
                else
                    return ''    
            },
            previewdata(){
                //public_print_with_dir_on_one_page("printdiv",1,this.doc.direction);
                public_preview_with_dir("partprintdiv",1,this.doc.direction);
            },
            printdata(){
                //public_print_with_dir_on_one_page("printdiv",1,this.doc.direction);
                public_print_with_dir("partprintdiv",1,this.doc.direction);
            },
            reload(){
                this.handleQuery()
            }
        },
        watch:{
            modelcfg (n){
                console.log(this.modelcfg)
            },
            cgdetail (n){
                console.log(n)
                this.handleQuery()
            },
            picidata:{
                handler: function(val, oldVal){
                    if(val!=null){
                        this.handleQuery()
                    }
                },
                // 深度观察监听
                deep: true
            }
        }
    }
</script>

<style type="text/css">
.printtd{
    padding-top:5px;
    padding-bottom:5px;
    height:40px;
}
</style>