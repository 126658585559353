<template>
    <div>
        <div v-for="(item_0,index) in groupdata"  style="page-break-after: always;margin-top: 20px;border:1px solid">
            <div style="margin: 0 auto;float: right;">
                <Button type="warning" style="margin-right: 70px;" @click.stop="previewdata('cghtprintdiv_'+index)">打印预览</Button>
                <Button type="primary" style="margin-right: 40px;" @click.stop="printdata('cghtprintdiv_'+index)">直接打印</Button>
            </div>
            <div :id="'cghtprintdiv_'+index" style="width:850px;height:1500px;margin:10px auto;clear: both;padding: 25px 40px;">
                <p class="MsoNormal" style="text-align: center;" align="center"><strong><span style="font-family: 宋体; font-size: 18pt;"><span style="font-family: 宋体;">采</span> <span style="font-family: 宋体;">购</span> <span style="font-family: 宋体;">合</span> <span style="font-family: 宋体;">同</span></span></strong></p>
<p class="MsoNormal" style="margin-right: 200px;word-break: break-all; text-align: right;" align="right"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;">合同编号：</span><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
<p class="MsoNormal"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;"><span style="font-family: 宋体;">甲方：</span><span style="font-family: Calibri;">{{modelcfg.companyname}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="font-family: 宋体;margin-left: 200px;">乙方： {{item_0.supplier}}</span></span></p>
<p class="MsoNormal"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;">签订时间：</span><span style="margin-left:100px;mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;">&nbsp;</span><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;">签订地点：&nbsp;甲方办公室</span></p>
<p class="MsoNormal"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;">该合同经过甲乙双方协商，根据中华人民共和国《合同法》友好约定由乙方为甲方提供相关产品，就有关事项达成如下协议：</span></p>
<p class="MsoNormal"><!-- [if !supportLists]--><span style="font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;">一、</span><!--[endif]--><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;">产品规格、价格等（如果产品较多可以附表）</span></p>
<table style="border-collapse: collapse; width: 80%; height: 21px;" border="1">
    <tbody>
    <tr style="height: 21px;">
        <td style="width: 13.8365%; height: 21px;">序号</td>
        <td style="width: 13.8365%; height: 21px;">零部件名称</td>
        <td style="width: 13.8365%; height: 21px;">规格型号</td>
        <td style="width: 13.8365%; height: 21px;">计量单位</td>
        <td style="width: 13.9623%; height: 21px;">价格（RMB）</td>
        <td style="width: 13.9623%; height: 21px;">是否含税</td>
        <td style="width: 13.9623%; height: 21px;">备注</td>
    </tr>
    <tr v-for="(item,index) in item_0.data">
        <td style="width: 13.8365%;">{{index+1}}</td>
        <td style="width: 13.8365%;">{{item.product}}</td>
        <td style="width: 13.8365%;">{{item.productmodelno==null ? item.product : item.productmodelno}}</td>
        <td style="width: 13.8365%;">&nbsp;</td>
        <td style="width: 13.9623%;">&nbsp;</td>
        <td style="width: 13.9623%;">&nbsp;</td>
        <td style="width: 13.9623%;">&nbsp;</td>
    </tr>
    <tr v-for="index in (10-item_0.data.length)">
        <td style="width: 13.8365%;">{{index + item_0.data.length}}</td>
        <td style="width: 13.8365%;"></td>
        <td style="width: 13.8365%;"></td>
        <td style="width: 13.8365%;"></td>
        <td style="width: 13.9623%;"></td>
        <td style="width: 13.9623%;"></td>
        <td style="width: 13.9623%;"></td>
    </tr>
    </tbody>
    </table>
<p class="MsoNormal"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;"><span style="font-family: 宋体;">二、质量标准与技术服务：按照乙方向甲方提供的产品样件、甲方技术部依据相关产品数据和规格进行实验或试装，合格后甲乙双方制定产品标准，乙方必须保证给甲方批量供货时的产品与送样件的技术标准、材料规格、性能要求一致，如果在合作期间有更新或与产品标准不一致的情况，乙方必须提前征得甲方同意！否则根据产品的单价给予</span><span style="font-family: Calibri;">20 % </span><span style="font-family: 宋体;">的考核每件。乙方向甲方承诺的产品返修比例为</span><span style="font-family: Calibri;">1% </span><span style="font-family: 宋体;">，乙方产品除去误判或误退后返修比例高于</span><span style="font-family: Calibri;">1%</span><span style="font-family: 宋体;">的，甲方向乙方征收当月</span><span style="font-family: Calibri;">5% </span><span style="font-family: 宋体;">货款作为甲方公司的售后费用，且甲方有条件提出终止合同。客户在使用过程中因乙方产品给客户造成重大损失的，乙方应无条件承担相关费用。</span></span></p>
<p class="MsoNormal"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-family: 宋体;">无论双方约定的产品标准如何，其标准至少符合</span><span style="font-family: Calibri;">GB17761-2018</span><span style="font-family: 宋体;">的要求。</span></span></p>
<p class="MsoNormal"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;"><span style="font-family: 宋体;">三、产品验收：由甲方按照双方签订的技术标准在甲方所在仓库进行验收，乙方所送产品，经检验不合格的由乙方负责更换，并且必须在规定的时间内重新发货到甲方仓库，如果因为乙方产品的批量问题造成甲方停产的，乙方必须承担甲方停产所造成的</span><span style="font-family: Calibri;">25%</span><span style="font-family: 宋体;">损失；经检验合格的产品甲方开具入库单，由乙方保存作为月底对账的凭证。</span></span></p>
<p class="MsoNormal"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;"><span style="font-family: 宋体;">四、包装标准：小件产品根据乙方常规方式包装，应保证物流在运输过程中不易破损，若送达甲方公司的产品包装箱有较大破损，甲方有条件提出拒收；每箱或每件应注明具体单箱数量，且保证所标数量与箱内一致，若甲方在抽检过程中发现有漏装现象，将对乙方</span><span style="font-family: Calibri;">1:5</span><span style="font-family: 宋体;">的考核。</span></span></p>
<p class="MsoNormal"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;">五、交（提）货方式、地点：根据甲乙双方协商具体的发货地点，原则上是乙方送货到甲方仓库，每批货都必须出具规范的送货单。</span></p>
<p class="MsoNormal"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;">六、结算方式、时间</span><span style="font-family: 宋体; font-size: 10.5pt;">：<u style="width:100px">&nbsp;&nbsp;</u></span></span><span style="margin-left: 80px;mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;"><span style="font-family: 宋体;">打款，次月</span><span style="font-family: Calibri;">15</span><span style="font-family: 宋体;">号结算上月货款。 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span></p>
<p class="MsoNormal"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;"><span style="font-family: 宋体;">七、计划下达方式以及交货时间：甲方将根据当月的销售任务下达采购订单给乙方，乙方收到传真或通知后在</span><span style="font-family: Calibri;">24</span><span style="font-family: 宋体;">小时内给予回传或电话回复，若不回传或回复将视为乙方默认该计划，乙方应按照甲方的交货节点准时交货，否则造成甲方停产的将会乙方进行 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;供货能力 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;考核；</span></span></p>
<p class="MsoNormal"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;">八、本协议解除条件：不可抗拒因素或乙方严重违约或产品严重影响甲方正常使用的。</span></p>
<p class="MsoNormal"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;"><span style="font-family: 宋体;">九、售后服务：乙方为甲方提供的产品包换周期为甲方销售之日起</span> &nbsp;&nbsp;<span style="font-family: Calibri;">3</span><span style="font-family: 宋体;">个 月内，在规定时间内乙方无条件接受甲方因质量问题退货。</span></span></p>
<p class="MsoNormal"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;">十、协议争议的解决办法：本协议在履行过程中发生争议，由双方当事人协商解决，协商不成的，依法向甲方所在人民法院起诉。</span></p>
<p class="MsoNormal"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;">十一、其他约定事项：</span></p>
<p class="MsoNormal"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;"><span style="font-family: Calibri;">1</span><span style="font-family: 宋体;">、乙方必须保质保量供货，服从甲方的产品技术标准的要求，其他《质量协议》、《采购计划单》、《产品售后服务协议》与本合同同等有效；</span></span></p>
<p class="MsoNormal"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;"><span style="font-family: Calibri;">2</span><span style="font-family: 宋体;">、乙方必须按照甲方的订单要求按时按质提供产品，若不能按照按质提供产品对甲方所造成的损失，甲方将向乙方采取索赔或罚款等处理方法，具体由相关条款；</span></span></p>
<p class="MsoNormal"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;"><span style="font-family: Calibri;">3</span><span style="font-family: 宋体;">、请乙方在每月</span><span style="font-family: Calibri;">1-5</span><span style="font-family: 宋体;">号期间来人或用其他方式与甲方财务核对上月账目，过期对账的视同认可甲方当月的入库数量；</span></span></p>
<p class="MsoNormal"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;"><span style="font-family: Calibri;">4</span><span style="font-family: 宋体;">、甲方产品售后对用户实行三包，若因乙方责任造成较大损失和危害，将由乙方承担；</span></span></p>
<p class="MsoNormal"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;"><span style="font-family: Calibri;">5</span><span style="font-family: 宋体;">、乙方必须向甲方提供质量稳定的产品，对质量不稳定的产品必须提出改进方案并实施，以达到甲方技术质量部门的质量目标。</span></span></p>
<p class="MsoNormal"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;"><span style="font-family: Calibri;">6</span><span style="font-family: 宋体;">、乙方对甲方提供的任何资料予以保密，若因乙方保密不当造成甲方产生损失，乙方需承担责任，必要时承担刑事责任。</span></span></p>
<p class="MsoNormal"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;">十二、本合同书一式两份，双方各执一份，具有同等法律效力。</span></p>
<p class="MsoNormal"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;">&nbsp;</span></p>
<div style="height: 130px;">

</div>
<table align="left" width="900px" style="margin-top: 25px;">
    <tr>
        <td style="width: 50%;"><strong>甲方: {{modelcfg.companyname}}</strong></td>
        <td style="width: 50%;"><strong>乙方：{{item_0.supplier}}</strong></td>
    </tr>
    <tr>
        <td><strong>日期: &nbsp; &nbsp; </strong></td>
        <td><strong>日期: &nbsp; &nbsp; </strong></td>
    </tr>
</table>
<p class="MsoNormal"><span style="mso-spacerun: 'yes'; font-family: 宋体; mso-ascii-font-family: Calibri; mso-hansi-font-family: Calibri; mso-bidi-font-family: 'Times New Roman'; font-size: 10.5000pt; mso-font-kerning: 1.0000pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></p>
            </div>
        </div>
    </div>
</template>
<script>
import {getPrinters,public_print,public_print_with_dir,public_preview_with_dir} from '@/zsjs/zskjprinter.js'
import * as sysTool from '@/zsjs/systemTool.js'
export default {
    name:'cghtview',
    props:['suppliers','cgdetail'],
    data(){
        return {
            logoninfo: this._self.$root.$store.state.LogonInfo,
            modelcfg:this._self.$root.$store.state.ModelCfg,
            companyid: this._self.$root.$store.state.LogonInfo.companyid,
            formInline:{
                parentid:null
            },
            groupdata:[]
        }
    },
    mounted(){
        this.groupdata = this.convertdata()
    },
    methods:{
        convertdata(){
            var map = {},
             dest = [];
            for(var i = 0; i < this.suppliers.length; i++){
                 var ai = this.suppliers[i];
                 if(!map[ai.supplier]){
                  dest.push({
                    supplier: ai.supplier,
                   data: [ai]
                  });
                  map[ai.supplier] = ai;
                 }
                else{
                  for(var j = 0; j < dest.length; j++){
                   var dj = dest[j];
                   if(dj.supplier == ai.supplier){
                        dj.data.push(ai);
                        break;
                   }
                    }
                }
            }
            return dest;
        },
        previewdata(divid){
            console.log(divid)
            public_preview_with_dir(divid,1,1);
        },
        printdata(divid){
            console.log(divid)
            public_print_with_dir(divid,1,1);
        },
    }
}
</script>